<template lang="pug">
  app-input-wrapper(v-bind='wrapperProps' v-slot='{ inputId, hasErrors, errorId }')
    select._select(
      :id='inputId'
      :style='dynamicStyles'
      @change='change'
      v-a11y-required='required'
      :aria-invalid='hasErrors'
      :aria-errormessage='errorId'
      :aria-label='hideLabel ? label : null'
      :readonly='readonly'
      :disabled='disabled'
    )
      slot
      option(
        v-for='opt in defaultOpts'
        :class='$style.option'
        :selected='opt.value === value'
        :disabled='opt.disabled'
        :value='opt.value'
        :style='dynamicOptionStyles(opt)'
        :aria-label="opt['aria-label']"
      ) {{ opt.name }}
      optgroup(v-for='group in optGroups' :label='group.name')
        option(
          v-for='opt in group.list'
          :class='$style.option'
          :selected='opt.value === value'
          :disabled='opt.disabled'
          :value='opt.value'
          :style='dynamicOptionStyles(opt)'
          :aria-label="opt['aria-label']"
        ) {{ opt.name }}
</template>

<script>
import isArray from 'lodash/isArray'
import AppInputWrapper, { WrapperProps, collectProps } from '@/components/app-input-wrapper'

export default {
  name: 'app-select-input',
  components: {
    AppInputWrapper,
  },
  props: {
    ...WrapperProps,
    value: {
      type: [String, Number]
    },
    options: {
      type: [Array, Object]
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapperProps () {
      return collectProps(this, WrapperProps)
    },
    defaultOpts () {
      if (!this.options) return []
      if (isArray(this.options)) return this.options
      return this.options.list || []
    },
    optGroups () {
      if (!this.options) return []
      if (isArray(this.options)) return []
      return this.options.groups || []
    },
    dynamicStyles () {
      if (this.value) return {}
      return { color: '#757575' }
    }
  },
  methods: {
    change (event) {
      this.$emit('input', event.target.value)
    },
    dynamicOptionStyles (opt) {
      if (opt.disabled) return { color: '#999' }
      return {}
    }
  }
}
</script>

<style lang="scss" module>

.select {
  width: 100%;
  display: inline-block;
  height: 2rem;
  font-size: 0.875rem;
  border: 0.0625rem solid #ced4da;
  border-radius: .25rem;
  vertical-align: middle;
  padding-left: 0.5rem;

  &[readonly] {
    border-color: transparent;
    background-color: transparent;
    background: none !important;
  }

  @supports (appearance: none) {
    padding: .25rem 1.75rem .25rem .5rem;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 0.5rem 0.625rem;
    appearance: none;

    &[disabled] {
      background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23959595' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
      background-size: 0.5rem 0.625rem;
    }
  }
}

.option {
  /* IE inherits the parent select element's color otherwise */
  color: black;
}

</style>
