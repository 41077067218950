<template lang="pug">
  form#header-login-form(:action='formAction' method='POST' @submit='submit')
    input(type='hidden' name='action' value='POST-LOGIN-INFO')
    input(type='hidden' name='request_url' :value='currentUrl')

    label#header-username-label(for='header-username-input') Username
    input#header-username-input(type='text' name='username' size='12')

    label#header-password-label(for='header-password-input') Password
    input#header-password-input(
      ref='pwinput'
      name='password'
      size='12'
      :type='showPassword ? "text" : "password"'
    )
    button#header-password-toggle(
      type='button'
      :title='toggleTitleText'
      @click='showPassword = !showPassword'
    )
      icon._toggle_icon(:name='showPassword ? "md-visibility-off" : "md-visibility"')

    input#header-login-submit(type='submit' name='login_button' value='Login')

    #header-login-links
      app-link#header-login-help(to='/help/HelpTopic' :params='{ shortName: "Forgot Password"}') Need help logging in?
      br
      app-link#header-sign-up(to='/common/NewSubscription') Sign Up Now!
</template>

<script>
import AppLink from '@/components/app-link'
import Icon from 'vue-awesome/components/Icon'
import '@/components/icons/md-visibility'
import '@/components/icons/md-visibility-off'

export default {
  name: 'header-login-form',
  components: {
    AppLink,
    Icon
  },
  data: () => ({
    showPassword: false
  }),
  computed: {
    toggleTitleText () {
      return this.showPassword
        ? 'Click to hide password'
        : 'Click to show password'
    },
    formAction () {
      return this.$store.getters.contextPath
      + this.$store.getters['locale/path']
      + '/common/Login'
    },
    currentUrl () {
      // IE will reuse the history state from the current page on the
      // logged in version of the page unless we modify the URL in some
      // way, thus the _login param.
      if (Object.keys(this.$route.query).length) {
        return this.$store.getters.contextPath + this.$route.fullPath + '&_login=1'
      } else {
        return this.$store.getters.contextPath + this.$route.fullPath + '?_login=1'
      }
    }
  },
  methods: {
    submit () {
      // needs to be turned back into a password input type
      // on submit for password managers to recognize the field
      this.$refs.pwinput.setAttribute('type', 'password')
    },
  }
}
</script>

<style lang="scss">
#header-login-form {
  display: inline-grid;
  align-items: center;
  gap: 0.125rem 0.125rem;
  font-size: x-small;
}

@mixin label {
  color: #666;
  font-size: x-small;
  text-align: right;
  margin-right: 0.125rem;
}

@mixin input {
  width: 10em;
  border: 1px solid #878787;
  font-size: x-small;
}

#header-username-label {
  grid-row: 1;
  grid-column: 1;
  @include label;
}

#header-username-input {
  grid-row: 1;
  grid-column: 2;
  @include input;
}

#header-password-label {
  grid-row: 2;
  grid-column: 1;
  @include label;
}

#header-password-input {
  grid-row: 2;
  grid-column: 2;
  @include input;
}

#header-password-toggle {
  grid-row: 2;
  grid-column: 3;

  background-color: #f8f8f8; /* For IE 9 and earlier */
  background: linear-gradient(#fafafa, #f6f6f6);
  border: 1px solid #cfcfcf;
  border-radius: 0.5em; /* CSS 3, not supported in all older browsers */
  cursor: pointer;
  color: #666;

  &:active {
    color: black;
    border-color: #afafaf;
    box-shadow: 0px 1px #f0f0f0 inset;
  }

  &:hover {
    border-color: #aaa;
  }
}

#header-login-submit {
  grid-row: 3;
  grid-column: 2;
  cursor: pointer;
  font-size: 12px;
}

#header-login-links {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column: 4;
  justify-self: center;
  margin-left: 1em;
}

#header-login-help {
}

#header-sign-up {
  font-size: small;
  font-weight: bold;
}
</style>

<style lang="scss" module>
.toggle_icon {
  display: block;
  width: 14px;
  height: 14px;
}
</style>
